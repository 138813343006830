import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Autocomplete,
  LinearProgress,
  IconButton,
  Snackbar,
  List,
  ListItem,
  ListItemButton,
  Alert,
} from '@mui/material'
import React, {useEffect} from 'react'
import ReactPlayer from 'react-player'
import suggestions from './suggestions.json'
import titles from './titles.json'
import InfoIcon from '@mui/icons-material/Info'
import SettingsIcon from '@mui/icons-material/Settings'
import CloseIcon from '@mui/icons-material/Close'
import MovieIcon from '@mui/icons-material/Movie'
import SearchIcon from '@mui/icons-material/Search'
import DangerousIcon from '@mui/icons-material/Dangerous'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Firestore,
  getDoc,
  doc,
  updateDoc,
  increment,
} from 'firebase/firestore/lite'
import ReactGA from 'react-ga4'

const options = suggestions.suggestions.reduce((prev, curr) => {
  if (!prev.includes(curr)) {
    prev.push(curr)
  }
  return prev
}, [] as Array<string>)

type TryType = 'Skip' | 'Wrong' | 'Right'

const toMatch = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
]
const isMobile = toMatch.some((toMatchItem) => {
  return navigator.userAgent.match(toMatchItem)
})

const startingDate = new Date('2024-05-06')
// Get today's date
const today = new Date()
// Calculate the difference in days
const differenceInDays = Math.floor(
  (today.getTime() - startingDate.getTime()) / (1000 * 60 * 60 * 24),
)
// Get the index of the movie for today
const index = differenceInDays % titles.titles.length
const currentTitle = titles.titles[index]
const lastHint = [
  titles.titles[index].title,
  suggestions.suggestions[index],
  suggestions.suggestions[(index * 2) % suggestions.suggestions.length],
].sort(() => Math.random() - 0.5)

/* TODO
  - Better design
  - Add score system like global.org
  - Add leader board?

  Bring people
    - social media 
    - Make a blog
    - Posts can be reviews about Netflix shows written with chatgpt using the sinopsis and rotten tomatoes scores as input.

  Make money
    - video ad after finishing the game
    - ⁠one time payment to play unlimited
*/

type AppProps = {
  db: Firestore
}

type EngagementType = {
  players: number
  shares: number
}

type ShareType =
  | 'Twitter'
  | 'Instagram'
  | 'Facebook'
  | 'WhatsApp'
  | 'Telegram'
  | 'Pinterest'
  | 'Copy'

async function getEngagement(db: Firestore) {
  const docRef = doc(db, 'system', 'engagement')
  const docSnap = await getDoc(docRef)

  return docSnap.data()
}

const sendPlayerEvent = async (
  shouldIncreaseCounter: boolean,
  type: TryType,
  currentDate: string,
  db: Firestore,
) => {
  ReactGA.send({hitType: 'event', eventCategory: type, eventAction: 'Play'})

  if (shouldIncreaseCounter) {
    const engagementRef = doc(db, 'system', 'engagement')
    await updateDoc(engagementRef, {
      players: increment(1),
    })

    const triedRef = doc(db, 'system', 'tries')
    await updateDoc(triedRef, {
      [`${currentDate}.players`]: increment(1),
    })
  }
}

const sendShareEvent = async (type: ShareType, db: Firestore) => {
  ReactGA.send({hitType: 'event', eventCategory: type, eventAction: 'Share'})

  const engagementRef = doc(db, 'system', 'engagement')

  await updateDoc(engagementRef, {
    shares: increment(1),
  })
}

const updateCompletedTries = async (currentDate: string, db: Firestore) => {
  const triedRef = doc(db, 'system', 'tries')
  await updateDoc(triedRef, {
    [`${currentDate}.completed`]: increment(1),
  })
}

const App = ({db}: AppProps) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  // const [imageUrl, setImageUrl] = React.useState<string>('')
  const playerRef = React.useRef<ReactPlayer>(null)
  const [notification, setNotification] = React.useState<{
    message: string
    severity: 'success' | 'error'
  }>({message: '', severity: 'success'})
  const [isHowToPlayModalVisible, setIsHowToPlayModalVisible] =
    React.useState<boolean>(false)
  const [isSettingsModalVisible, setIsSettingsModalVisible] =
    React.useState<boolean>(false)
  const [isImageVisible, setIsImageVisible] = React.useState<boolean>(false)
  const [playedSeconds, setPlayedSeconds] = React.useState<number>(0)
  const [selectedTitle, setSelectedTitle] = React.useState<string>('')
  const [triesByDate, setTriesByDate] = React.useState<{
    [date: string]: TryType[]
  }>(() => {
    const storedTries = localStorage.getItem('gameflix_tries')
    return storedTries ? JSON.parse(storedTries) : {}
  })
  const [settings, setSettings] = React.useState<{
    videoSetting: number
    areHintsEnabled: boolean
  }>(() => {
    const storedSettings = localStorage.getItem('gameflix_settings')
    return storedSettings
      ? JSON.parse(storedSettings)
      : {videoSetting: 0, areHintsEnabled: true}
  })
  const currentDate = new Date().toISOString().split('T')[0]
  const currentTries = triesByDate[currentDate] || []
  const isGameOver = currentTries.length === 5 || currentTries.includes('Right')
  const timeLimit = currentTries.length * 2 + 2
  const [playing, setPlaying] = React.useState<boolean>(true)
  const [muted, setMuted] = React.useState<boolean>(true)
  const [isPlayerCounterIncreased, setIsPlayerCounterIncreased] =
    React.useState<boolean>(false)
  const [engagement, setEngagement] = React.useState<EngagementType | null>(
    null,
  )

  useEffect(() => {
    getEngagement(db).then((data) => {
      const engagement = data as unknown as EngagementType
      if (engagement) {
        engagement.players += 2000
        engagement.shares += 2000
      }

      setEngagement(engagement)
    })
  }, [db])

  const handleProgress = (state: {playedSeconds: number}) => {
    if (isGameOver) {
      return
    }

    const currentSeconds =
      state.playedSeconds >= 10 ? state.playedSeconds - 10 : state.playedSeconds
    setPlayedSeconds(currentSeconds)

    if (currentSeconds >= timeLimit) {
      setIsImageVisible(true)
      setPlaying(false)
      playerRef.current?.seekTo(isGameOver ? 0 : 10, 'seconds')
    }
  }

  const getGuesses = () => {
    let guessIcons = currentTries.reduce((pre, curr) => {
      let icon = '⬛ '

      if (curr === 'Wrong') {
        icon = '🟥 '
      } else if (curr === 'Right') {
        icon = '🟩 '
      }

      return pre + icon
    }, '')

    for (let i = currentTries.length; i < 5; i++) {
      guessIcons += `⬜${i === 5 ? '' : ' '}`
    }

    return guessIcons
  }

  const getShareText = () => {
    var dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    var today = new Date()

    return `Gameflix - ${today.toLocaleDateString('en-US', dateOptions)}
${getGuesses()}
${
  currentTries.includes('Right')
    ? `I did it in ${currentTries.length} ${
        currentTries.length > 1 ? 'tries' : 'try'
      }.`
    : 'I almost had it!'
}
Can you guess today's movie?
https://gameflix.app`
  }

  const resetPlayer = (tries: TryType[]) => {
    playerRef.current?.seekTo(
      tries.length === 5 || tries.includes('Right') ? 0 : 10,
      'seconds',
    )

    setPlaying(true)
    setMuted(true)

    setTimeout(() => {
      setMuted(false)
      setIsImageVisible(false)
    }, 300)

    if (tries.length > 0) {
      sendPlayerEvent(
        !isPlayerCounterIncreased && tries.length === 1,
        tries[tries.length - 1],
        currentDate,
        db,
      )
      !isPlayerCounterIncreased && setIsPlayerCounterIncreased(true)
    }
  }

  const handleSubmit = () => {
    if (!selectedTitle) return

    setSelectedTitle('')
    const newTries = [
      ...currentTries,
      selectedTitle.toLocaleLowerCase() ===
      currentTitle.title.toLocaleLowerCase()
        ? 'Right'
        : 'Wrong',
    ] as TryType[]
    setTriesByDate({...triesByDate, [currentDate]: newTries})
    resetPlayer(newTries)
  }

  const handleSkip = () => {
    const newTries = [...currentTries, 'Skip'] as TryType[]
    setTriesByDate({...triesByDate, [currentDate]: newTries})
    resetPlayer(newTries)
  }

  const handleVisibilityChange = () => {
    if (document.hidden) setMuted(true)
    else setMuted(false)
  }

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    setTimeout(() => {
      if (currentTries.length === 0) {
        setNotification({
          message: 'Skip to watch more of the trailer and get hints!',
          severity: 'success',
        })
      }
    }, 5000)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  React.useEffect(() => {
    if (!isGameOver) {
      playerRef.current?.seekTo(10, 'seconds')
    }
  }, [isGameOver])

  React.useEffect(() => {
    localStorage.setItem('gameflix_tries', JSON.stringify(triesByDate))
    if (
      !muted &&
      triesByDate[currentDate] &&
      triesByDate[currentDate].includes('Right')
    ) {
      updateCompletedTries(currentDate, db)
    }
  }, [triesByDate, muted, currentDate, db])

  React.useEffect(() => {
    localStorage.setItem('gameflix_settings', JSON.stringify(settings))
  }, [settings])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        className='header'
        style={{
          marginTop: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <img
          id='logo'
          src='./logo.png'
          alt='Gameflix - Guess movies - Wordle for movies'
        />
        <div style={{display: 'flex', gap: 10}}>
          <InfoIcon
            fontSize='large'
            color='error'
            style={{cursor: 'pointer', color: 'rgb(229, 9, 20)'}}
            onClick={() => {
              setIsHowToPlayModalVisible(true)
            }}
          />
          <SettingsIcon
            fontSize='large'
            color='error'
            style={{cursor: 'pointer', color: 'rgb(229, 9, 20)'}}
            onClick={() => {
              setIsSettingsModalVisible(true)
            }}
          />
        </div>
      </div>
      <hr
        className='divider'
        style={{
          height: 1,
          marginBottom: 30,
          marginTop: 0,
          backgroundColor: 'rgb(229, 9, 20)',
          borderWidth: 0,
          borderRadius: 10,
        }}
      />
      {engagement?.players && !isGameOver && (
        <span
          className='engagement-text'
          style={{
            fontSize: 18,
            marginBottom: 20,
            textAlign: 'center',
          }}
        >
          {'Join the fun and guess the movie of the day!'}
          <br />
          {`${engagement?.players.toLocaleString()} players have already jumped in.`}
        </span>
      )}

      <div
        className='video-player'
        onClick={() => {
          if (isGameOver) {
            window.open(
              `https://www.netflix.com/search?q=${currentTitle.title}`,
              '_blank',
            )
            setPlaying(false)
          } else {
            resetPlayer(currentTries)
          }
        }}
      >
        {!isGameOver && (
          <>
            <div
              className='player-overlay-top'
              style={{
                backgroundColor: 'black',
                zIndex: 1,
                position: 'absolute',
              }}
            ></div>
            <div
              className='player-overlay-bottom'
              style={{
                backgroundColor: 'black',
                zIndex: 1,
                position: 'absolute',
              }}
            ></div>
          </>
        )}
        <img
          className='player-image'
          src={isGameOver ? './player-image2.png' : './player-image.png'}
          style={{
            opacity: isImageVisible || isGameOver ? 1 : 0,
            zIndex: 2,
            display: 'block',
            position: 'absolute',
            borderColor: 'white',
            border: 'solid',
            borderRadius: '10px',
          }}
          alt='Video player'
        />
        <ReactPlayer
          width={'100%'}
          height={'100%'}
          ref={playerRef}
          url={currentTitle.url}
          playing={playing}
          onProgress={handleProgress}
          muted={muted || settings.videoSetting === 1}
          progressInterval={100}
          playsinline
          onBuffer={() => {
            setIsImageVisible(true)
          }}
          onBufferEnd={() => {
            setIsImageVisible(false)
          }}
          config={{
            youtube: {
              onUnstarted: () => {
                window.location.reload()
              },
            },
          }}
          style={{filter: settings.videoSetting === 2 ? 'blur(50px)' : 'none'}}
        />
      </div>

      <div className='guess-input' style={{marginTop: 25}}>
        {!isGameOver && (
          <div
            style={{display: 'flex', alignItems: 'center', marginBottom: 10}}
          >
            <span>{`0:${playedSeconds >= 10 ? '' : '0'}${Math.max(
              0,
              Number(playedSeconds.toFixed(0)),
            )}`}</span>
            <LinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'rgb(229, 9, 20)',
                },
              }}
              style={{
                width: '100%',
                margin: '0 10px',
                backgroundColor: '#d9d9d9',
              }}
              variant='determinate'
              value={playedSeconds >= 1 ? (playedSeconds / timeLimit) * 100 : 0}
            />
            <span>{`0:${timeLimit >= 10 ? '' : '0'}${Math.max(
              2,
              Number(timeLimit.toFixed(0)),
            )}`}</span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 10,
            marginTop: 30,
          }}
        >
          {isGameOver && (
            <div
              style={{
                marginBottom: 30,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {currentTries.includes('Right') ? (
                <span style={{fontSize: 18}}>You found it!</span>
              ) : (
                <span style={{fontSize: 18}}>
                  Almost had it! It was <b>{currentTitle.title}</b>.
                </span>
              )}
              <span style={{fontSize: 18, textAlign: 'center'}}>
                Come back tomorrow for a new game.
              </span>
            </div>
          )}
          <span style={{fontSize: 18}}>Your guesses:</span>
          <span style={{fontSize: 24}}>{getGuesses()}</span>
          {!isGameOver && settings.areHintsEnabled && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                flexWrap: 'wrap',
              }}
            >
              {currentTries.length >= 1 && (
                <span style={{fontSize: 18}}>
                  Genre: {currentTitle.hints[0]}
                </span>
              )}
              {currentTries.length >= 2 && (
                <span style={{fontSize: 18}}>
                  Directors: {currentTitle.hints[1]}
                </span>
              )}
              {currentTries.length >= 3 && (
                <span style={{fontSize: 18}}>
                  Stars: {currentTitle.hints[2]}
                </span>
              )}
            </div>
          )}
        </div>
        {!isGameOver ? (
          <div>
            {currentTries.length < 4 ? (
              <Autocomplete
                options={options}
                value={selectedTitle}
                onChange={(event, newValue) => setSelectedTitle(newValue ?? '')}
                filterOptions={(options, params) => {
                  const inputValue = params.inputValue.toLowerCase()
                  const filteredOptions = options.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(params.inputValue.toLowerCase()),
                  )

                  // Calculate a score for each option based on the index of the match
                  const scoredOptions = filteredOptions.map((option) => {
                    const index = option.toLowerCase().indexOf(inputValue)
                    const score = index >= 0 ? index : Infinity // Use Infinity if not found
                    return {option, score}
                  })

                  // Sort the options based on the score (lower scores first)
                  scoredOptions.sort((a, b) => a.score - b.score)

                  // Return the sorted options
                  return scoredOptions.map(({option}) => option)
                }}
                blurOnSelect
                sx={{
                  '.Mui-focused.MuiFormLabel-root, .MuiFormLabel-root.MuiFormLabel-filled':
                    {
                      display: 'none',
                    },
                  '.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline':
                    {
                      border: 'none',
                    },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Guess the movie'
                    variant='outlined'
                    style={{
                      backgroundColor: 'white',
                      borderRadius: 10,
                    }}
                  />
                )}
              />
            ) : (
              <List
                sx={{
                  bgcolor: 'white',
                  borderRadius: 5,
                  color: 'black',
                  padding: '0 20px',
                }}
              >
                {lastHint.map((option) => {
                  const labelId = `checkbox-list-label-${option}`

                  return (
                    <ListItem key={option}>
                      <ListItemButton onClick={() => setSelectedTitle(option)}>
                        <ListItemText
                          id={labelId}
                          primary={option}
                          style={{display: 'flex', flexWrap: 'wrap'}}
                        />
                        <ListItemIcon style={{minWidth: 'inherit'}}>
                          <Checkbox
                            edge='start'
                            tabIndex={-1}
                            checked={selectedTitle === option}
                            inputProps={{'aria-labelledby': labelId}}
                            style={{color: 'rgb(229, 9, 20)'}}
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Button
                variant='contained'
                style={{
                  backgroundColor: 'rgb(45,45,45)',
                  color: 'white',
                  minWidth: 110,
                }}
                onClick={handleSkip}
              >
                {`Skip ${currentTries.length < 4 ? '(+2s)' : ''}`}
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: 'rgb(229, 9, 20)',
                  color: 'white',
                  minWidth: 110,
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            {engagement?.shares && (
              <span style={{fontSize: 18, textAlign: 'center'}}>
                {`Spread the word! Join the
${engagement?.shares.toLocaleString()} who've already shared.`}
              </span>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: isMobile ? 300 : 400,
                marginTop: 15,
                gap: 15,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#000000',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <img
                  src={'./x.svg'}
                  onClick={() => {
                    const shareText =
                      getShareText() + '\n#Gameflix @gameflixapp'
                    const encodedShareText = encodeURIComponent(shareText)

                    let shareUrl = ''

                    // Check if it's a mobile device
                    if (/Android/i.test(navigator.userAgent)) {
                      // For Android, open Twitter app using deep link URL
                      shareUrl = `twitter://post?text=${encodedShareText}`
                    } else if (
                      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                      // @ts-ignore
                      !window.MSStream
                    ) {
                      // For iOS, open Twitter app using deep link URL
                      shareUrl = `twitter://post?message=${encodedShareText}`
                    } else {
                      // For other devices, open Twitter web intent
                      shareUrl = `https://twitter.com/intent/tweet?text=${encodedShareText}`
                    }

                    // Open Twitter app or web intent
                    window.open(shareUrl, '_blank')
                    sendShareEvent('Twitter', db)
                  }}
                  width={24}
                  height={24}
                  alt='X logo'
                />
              </div>
              {isMobile && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff',
                    borderRadius: 3,
                    width: 48,
                    height: 48,
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={'./ig.svg'}
                    onClick={async () => {
                      // Get canvas context
                      const canvas = canvasRef.current
                      const context = canvas?.getContext('2d')

                      if (!canvas || !context) return

                      // Clear canvas
                      context.clearRect(0, 0, canvas.width, canvas.height)

                      // Draw text on canvas
                      const txt = getShareText()
                      context.font =
                        '26px BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif'
                      context.fillStyle = '#ffffff'
                      var x = 80
                      var y = 400
                      var lineHeight = 32
                      var lines = txt.split('\n')

                      for (var i = 0; i < lines.length; i++)
                        context.fillText(lines[i], x, y + i * lineHeight)

                      // const logo = document.getElementById('logo')
                      // logo &&
                      //   context.drawImage(
                      //     logo as HTMLImageElement,
                      //     x,
                      //     y + i * lineHeight,
                      //   )

                      const destinationCanvas = document.createElement('canvas')
                      destinationCanvas.width = canvas.width
                      destinationCanvas.height = canvas.height

                      const destCtx = destinationCanvas.getContext('2d')

                      if (!destCtx) return
                      //create a rectangle with the desired color
                      destCtx.fillStyle = '#141414'
                      destCtx.fillRect(0, 0, canvas.width, canvas.height)

                      //draw the original canvas onto the destination canvas
                      destCtx.drawImage(canvas, 0, 0)

                      //finally use the destinationCanvas.toDataURL() method to get the desired output;

                      const dataUrl = destinationCanvas.toDataURL('image/png')
                      // setImageUrl(dataUrl)
                      const file = new File(
                        [await (await fetch(dataUrl)).blob()],
                        'gameflix.png',
                        {type: 'image/png'},
                      )

                      // Share generated image using navigator.share
                      try {
                        await navigator.share({
                          files: [file],
                        })
                      } catch (error) {
                        console.error('Error sharing:', error)
                      }
                      sendShareEvent('Instagram', db)
                    }}
                    width={24}
                    height={24}
                    alt='Instagram logo'
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#4267B2',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <img
                  src={'./fb.svg'}
                  onClick={() => {
                    const shareText = getShareText() + '\n#Gameflix'
                    const encodedShareText = encodeURIComponent(shareText)
                    const encodedUrl = encodeURIComponent(
                      'https://gameflix.app',
                    )

                    let shareUrl = ''

                    // Check if it's a mobile device
                    if (isMobile) {
                      shareUrl = `fb://share/?text=${encodedShareText}&href=${encodedUrl}`
                    } else {
                      // For other devices, open Facebook web share dialog
                      shareUrl = `https://www.facebook.com/dialog/share?app_id=935322860783290&display=popup&quote=${encodedShareText}&href=${encodedUrl}`
                    }

                    // Open Facebook app or web share dialog
                    window.open(shareUrl, '_blank')
                    sendShareEvent('Facebook', db)
                  }}
                  width={24}
                  height={24}
                  alt='Facebook logo'
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#25d366',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <img
                  src={'./whatsapp.svg'}
                  onClick={() => {
                    const shareText = getShareText()
                    const encodedShareText = encodeURIComponent(shareText)

                    let shareUrl = ''

                    // Check if it's a mobile device
                    if (isMobile) {
                      shareUrl = `whatsapp://send?text=${encodedShareText}`
                    } else {
                      // For other devices, open WhatsApp web share dialog
                      shareUrl = `https://web.whatsapp.com/send?text=${encodedShareText}`
                    }

                    // Open WhatsApp app or web share dialog
                    window.open(shareUrl, '_blank')
                    sendShareEvent('WhatsApp', db)
                  }}
                  width={24}
                  height={24}
                  alt='WhatsApp logo'
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#269bd7',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <img
                  src={'./telegram.svg'}
                  onClick={() => {
                    const shareText = getShareText()
                    const encodedShareText = encodeURIComponent(shareText)

                    let shareUrl = ''

                    // Check if it's a mobile device
                    if (isMobile) {
                      shareUrl = `tg://msg?text=${encodedShareText}`
                    } else {
                      // For other devices, open Telegram website
                      shareUrl = `https://web.telegram.org/#/im?text=${encodedShareText}`
                    }

                    // Open Telegram app or website
                    window.open(shareUrl, '_blank')
                    sendShareEvent('Telegram', db)
                  }}
                  width={24}
                  height={24}
                  alt='Telegram logo'
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#CB2027',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <img
                  src={'./pinterest.svg'}
                  onClick={() => {
                    const shareText = getShareText()
                    const encodedShareText = encodeURIComponent(shareText)
                    const encodedUrl = encodeURIComponent(window.location.href)

                    let shareUrl = ''

                    // Check if it's a mobile device
                    if (isMobile) {
                      shareUrl = `pinterest://pin/create/button/?url=${encodedUrl}&description=${encodedShareText}`
                    } else {
                      // For other devices, open Pinterest website
                      shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodedUrl}&description=${encodedShareText}`
                    }

                    // Open Pinterest app or website
                    window.open(shareUrl, '_blank')
                    sendShareEvent('Pinterest', db)
                  }}
                  width={24}
                  height={24}
                  alt='Pinterest logo'
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'black',
                  borderRadius: 3,
                  width: 48,
                  height: 48,
                  cursor: 'pointer',
                }}
              >
                <ContentCopyIcon
                  onClick={() => {
                    const shareText = getShareText()

                    // Copy the text to the clipboard
                    navigator.clipboard
                      .writeText(shareText)
                      .then(() => {
                        setNotification({
                          message: 'Text copied to clipboard.',
                          severity: 'success',
                        })
                      })
                      .catch((err) => {
                        setNotification({
                          message: 'Failed to copy text to clipboard.',
                          severity: 'error',
                        })
                      })
                    sendShareEvent('Copy', db)
                  }}
                  width={24}
                  height={24}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {imageUrl && <img src={imageUrl} alt='Generated Image' />} */}
      <div className={'footer'} style={{marginTop: 'auto'}}>
        Gameflix © 2024 | Made with ❤️ by{' '}
        <a
          href='https://twitter.com/winnercrespo'
          target='_blank'
          rel='noreferrer'
          style={{
            color: 'inherit',
          }}
        >
          Winner Crespo
        </a>
      </div>
      <canvas
        ref={canvasRef}
        width={540}
        height={960}
        style={{display: 'none'}}
      ></canvas>
      <Dialog
        open={isHowToPlayModalVisible}
        onClose={() => {
          setIsHowToPlayModalVisible(false)
        }}
      >
        <div style={{backgroundColor: '#141414'}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle
              style={{
                color: 'white',
              }}
            >
              {'How to play?'}
            </DialogTitle>
            <IconButton
              onClick={() => {
                setIsHowToPlayModalVisible(false)
              }}
              style={{
                marginRight: 24,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText style={{maxWidth: '400px'}}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                  color: 'white',
                }}
              >
                <MovieIcon
                  style={{marginRight: 20, color: 'rgb(229, 9, 20)'}}
                  fontSize='large'
                />
                <span>
                  <b>Watch the Trailer:</b> Watch the trailer for a movie.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                  color: 'white',
                }}
              >
                <SearchIcon
                  style={{marginRight: 20, color: 'rgb(229, 9, 20)'}}
                  fontSize='large'
                />
                <span>
                  <b>Find the Title:</b> Choose the correct title from a list.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                  color: 'white',
                }}
              >
                <DangerousIcon
                  style={{marginRight: 20, color: 'rgb(229, 9, 20)'}}
                  fontSize='large'
                />
                <span>
                  <b>Try Again if Needed:</b> If you get it wrong or skip,
                  you'll see more of the trailer to help you guess.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 20,
                  color: 'white',
                }}
              >
                <ThumbUpIcon
                  style={{marginRight: 20, color: 'rgb(229, 9, 20)'}}
                  fontSize='large'
                />
                <span>
                  <b>Share Your Score:</b> Try to guess right with as few tries
                  as possible and share how you did!
                </span>
              </div>
              <div
                style={{display: 'flex', alignItems: 'center', color: 'white'}}
              >
                <CalendarTodayIcon
                  style={{marginRight: 20, color: 'rgb(229, 9, 20)'}}
                  fontSize='large'
                />
                <span>
                  <b>Come Back Daily:</b> Check out a new trailer every day.
                </span>
              </div>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        open={isSettingsModalVisible}
        onClose={() => {
          setIsSettingsModalVisible(false)
        }}
      >
        <div style={{backgroundColor: '#141414'}}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <DialogTitle
              style={{
                color: 'white',
              }}
            >
              {'Seeking more thrill?'}
            </DialogTitle>
            <IconButton
              onClick={() => {
                setIsSettingsModalVisible(false)
              }}
              style={{
                marginRight: 24,
                color: 'white',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText style={{width: '350px', color: 'white'}}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settings.areHintsEnabled}
                      style={{color: 'rgb(229, 9, 20)'}}
                      onClick={() => {
                        setSettings({
                          ...settings,
                          areHintsEnabled: !settings.areHintsEnabled,
                        })
                      }}
                    />
                  }
                  label='Show hints'
                  style={{marginBottom: 15}}
                />
                <FormLabel
                  id='buttons-group-label'
                  style={{color: 'white', fontWeight: 'bold', marginBottom: 15}}
                >
                  Video and audio?
                </FormLabel>
                <RadioGroup
                  aria-labelledby='buttons-group-label'
                  name='row-radio-buttons-group'
                  value={settings.videoSetting}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      videoSetting: Number(
                        (event.target as HTMLInputElement).value,
                      ),
                    })
                  }}
                >
                  <FormControlLabel
                    value='0'
                    control={<Radio style={{color: 'white', marginLeft: 10}} />}
                    label='Both'
                  />
                  <FormControlLabel
                    value='1'
                    control={<Radio style={{color: 'white', marginLeft: 10}} />}
                    label='Video only'
                  />
                  <FormControlLabel
                    value='2'
                    control={<Radio style={{color: 'white', marginLeft: 10}} />}
                    label='Audio only'
                  />
                </RadioGroup>
              </FormControl>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
      <Snackbar
        open={!!notification.message}
        autoHideDuration={5000}
        onClose={() => setNotification({...notification, message: ''})}
        message={notification}
      >
        <Alert
          onClose={() => setNotification({...notification, message: ''})}
          severity={notification.severity}
          sx={{width: '100%'}}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default App
