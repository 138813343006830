import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import ReactGA from 'react-ga4'
import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore/lite'

const firebaseConfig = {
  apiKey: 'AIzaSyCF-LwWQ-Ql4L8riGZgFGvN4-22S-JMWoU',
  authDomain: 'gameflix-42810.firebaseapp.com',
  projectId: 'gameflix-42810',
  storageBucket: 'gameflix-42810.appspot.com',
  messagingSenderId: '382861857224',
  appId: '1:382861857224:web:2467f4f3ad35f4b12d70fd',
  measurementId: 'G-5Z3EBRT2WM',
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

ReactGA.initialize('G-Z4DK7DQ7N7', {
  testMode: process.env.NODE_ENV !== 'production',
})

ReactDOM.render(
  <React.StrictMode>
    <App db={db} />
  </React.StrictMode>,
  document.getElementById('root'),
)
